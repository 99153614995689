.input-form-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: #f5f5f5;
    border-radius: 8px;
    min-width: 40ch; 
  }

  .container {
    position: relative;
  }
  
  .container > .MuiSnackbar-root {
    position: absolute;
    bottom: 0;
  }